<template>
  <div class="aboutupay">
    <div class="banner">
      <video ref="bannerVideo" class="video" muted loop autoplay>
        <source src="../assets/Website video.mp4" type="video/mp4" />
        <p>
          Your browser doesn't support HTML5 video. Here is a
          <a href="../assets/Website video.mp4">link to the video</a> instead.
        </p>
      </video>
      <img
        class="video-m-cover"
        src="../assets/Website video-post.jpg"
        alt=""
      />
      <div class="container">
        <strong>{{ $t(`Home['崭新生活 源于优付']`) }}</strong>
        <p>{{ $t(`Home['YOUR NEW LIFESRYLE BEGINS WITH UPAY']`) }}</p>
      </div>
      <div class="video-control-wrap">
        <i
          v-show="isPauseVideo"
          @click="playVideo"
          class="el-icon-video-play"
        ></i>
        <i
          v-show="!isPauseVideo"
          @click="pauseVideo"
          class="el-icon-video-pause"
        ></i>
      </div>
    </div>
    <Penel class="about" :title="$t(`Home['关于U-Pay']`)">
      <p>
        {{ $t("Home['about_text1']") }}
      </p>
      <p>
        {{ $t("Home['about_text2']") }}
      </p>
      <p>
        {{ $t("Home['about_text3']") }}
      </p>
    </Penel>
    <Penel class="use" :title="$t(`Home['为何使用U-Pay?']`)">
      <ul>
        <li v-for="item in howToUseList" :key="item.topic">
          <img :src="item.icon" :alt="item.topic" />
          <h3>{{ $t(`Home['${item.topic}']`) }}</h3>
          <strong>
            {{ $t(`Home['${item.text1}']`) }}
          </strong>
          <!-- <p>{{ $t(`Home['${item.text2}']`) }}</p> -->
        </li>
      </ul>
    </Penel>
    <div class="authentication">
      <div class="top">
        <p>
          <strong> {{ $t(`Home['安全认证']`) }}</strong
          ><br />
          {{ $t(`Home['多重防护，保障资讯安全']`) }}
        </p>
        <i class="iconfont el-icon-arrow-down"></i>
      </div>
      <div class="bottom container">
        <div class="item">
          <img src="../assets/image/home/PCIDSS@2x.png" alt="PCIDSS" />
          <p>
            {{
              $t(`Home['所有金融作業符合符合支付产业资料安全标准认证PCIDSS (Payment Card
            Industry Data Security Standard) 规定。']`)
            }}
          </p>
        </div>
        <div class="item">
          <a target="_blank" href="https://www.ipification.com/">
            <img
              src="../assets/image/home/BVL-Logo-Small@2x.png"
              alt="IPification"
            />
          </a>
          <p>
            {{
              $t(
                `Home['与Benefit Vantage Limited 合作 IPification 安全验证服务，让您消费更安心。']`
              )
            }}
          </p>
        </div>
      </div>
    </div>
    <Penel class="partner" :title="$t(`Home['我们的合作伙伴']`)">
      <ul>
        <li v-for="(item, i) in partner" :key="i">
          <a :href="item.href" target="_blank">
            <img :src="item.img" :alt="item.alt" />
          </a>
        </li>
      </ul>
    </Penel>
    <Penel class="download" :title="$t(`Home['立即下载']`)">
      <ul>
        <li>
          <a href="#">
            <img
              src="../assets/image/home/icon-Appstore@2x.png"
              alt="Appstore upay-app"
            />
          </a>
        </li>
        <li>
          <a href="#">
            <img
              src="../assets/image/home/icon-Android@2x.png"
              alt="Android upay-app"
            />
          </a>
        </li>
      </ul>
    </Penel>
  </div>
</template>

<script>
export default {
  name: 'aboutUPAY',
  data() {
    return {
      isPauseVideo: false
    }
  },
  computed: {
    howToUseList() {
      return [
        {
          icon: require('@/assets/image/home/icon1@2x.png'),
          topic: '支付、转帐',
          text1: '让您在外畅行无阻',
          text2: '付款扫一下， 快速支付完成，帮您智慧管理您的钱包。'
        },
        {
          icon: require('@/assets/image/home/icon2@2x.png'),
          topic: '生活帐单',
          text1: '日常缴费更便利',
          text2: '不用出门现场排队，线上就能完成缴款，帮您省力更省时。'
        },
        {
          icon: require('@/assets/image/home/icon3@2x.png'),
          topic: '现金反馈',
          text1: '买越多省越多',
          text2: 'UPAY与商户携手推出返现优惠方案，让您轻松购物省更多。'
        },
        {
          icon: require('@/assets/image/home/icon4@2x.png'),
          topic: 'UPoints',
          text1: '累积点数享优惠',
          text2: '消费累积点数，点数可折抵现金或好礼，轻松集点享受购物乐趣。'
        },
        {
          icon: require('@/assets/image/home/icon5@2x.png'),
          topic: '惊喜红包',
          text1: '聊天互动零距离',
          text2:
            '聊天特色惊喜红包派发功能，拉近您与朋友间的距离，增添聊天乐趣。'
        }
      ]
    },
    partner() {
      return [
        {
          img: require('../assets/image/home/comboticket logo@2x.png'),
          alt: 'comboticket',
          href: 'https://www.camboticket.com/'
        },
        {
          img: require('../assets/image/home/sunmi@2x.png'),
          alt: 'sunmi',
          href: 'https://www.sunmi.com/en/index'
        },
        {
          img: require('../assets/image/home/edeel@2x.png'),
          alt: 'edeel',
          href: ''
        },
        // {
        //   img: require('../assets/image/home/Dola Mall logo@2x.png'),
        //   alt: 'Dola Mall'
        // },
        // {
        //   img: require('../assets/image/home/BVL-Logo-Small logo@2x.png'),
        //   alt: 'BVL'
        // },
        // {
        //   img: require('../assets/image/home/Skybooking logo@2x.png'),
        //   alt: 'Skybooking'
        // },
        // {
        //   img: require('../assets/image/home/egets logo@2x.png'),
        //   alt: 'egets'
        // },
        { img: require('../assets/image/home/smart logo@2x.png'), alt: 'smart', href: 'https://www.smart.com.kh/' },
        this.$i18n.locale === 'kh' ? { img: require('../assets/image/home/cellcard-kh.png'), alt: 'cellcard', href: '' } : { img: require('../assets/image/home/cellcard-en.png'), alt: 'cellcard', href: '' }
      ]
    }
  },
  methods: {
    playVideo() {
      this.$refs.bannerVideo.play()
      this.isPauseVideo = false
    },
    pauseVideo() {
      this.$refs.bannerVideo.pause()
      this.isPauseVideo = true
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #e60013;
.aboutupay {
  .banner {
    position: relative;
    // background: url("../assets/image/home/首页banner@2x.jpg") no-repeat;
    // background-size: cover;
    background-position: center;
    .video {
      width: 100%;
      max-height: 100vh;
      object-fit: cover;
    }
    .video-m-cover {
      display: none;
    }
    .video-control-wrap {
      position: absolute;
      bottom: 50px;
      right: 50px;
      font-size: 50px;
      color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
    }
    .container {
      position: absolute;
      width: 100%;
      top: 40%;
      text-align: center;
      color: #ffffff;
      strong {
        font-size: 48px;
        font-weight: bold;
        /* text-shadow: -1px -1px 0 #e7e7e7, 1px -1px 0 #e7e7e7, -1px 1px 0 #a7a7a7, 1px 1px 0 #e7e7e7; */
        text-transform: uppercase;
      }
      p {
        margin-top: 10px;
        font-size: 21px;
        font-weight: 300;
        color: #ffffff;
        text-transform: uppercase;
      }
    }
    @media screen and (max-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
      .video {
        display: none;
      }
      .video-m-cover {
        display: block;
        height: 400px;
        width: 100%;
        object-fit: cover;
      }
      .video-control-wrap {
        display: none;
      }
      .container {
        text-align: center;
        top: 40%;
        strong {
          font-size: 36px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .about {
    padding-bottom: 60px;
    /deep/ .content {
      min-height: 336px;
      background: url("../assets/image/home/√-icon 背景@2x.png") no-repeat
        center;
      p {
        max-width: 896px;
        color: #333333;
        line-height: 31px;
        margin: 0 auto 31px;
      }
      @media screen and (max-width: 768px) {
        p {
          text-align: unset;
        }
      }
    }
  }
  .use {
    background: #f7f7f7;
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      font-size: 14px;
      li {
        display: flex;
        margin: 0 20px 25px;
        flex-direction: column;
        align-items: center;
        width: 185px;
        text-align: center;
        color: #333333;
        img {
          height: 130px;
          width: 130px;
        }
        h3 {
          font-weight: bold;
          line-height: 1.8;
          color: @baseColor;
        }
        strong {
          font-weight: bold;
        }
        p {
          margin-top: 10px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      ul {
        margin: 0 -18px;
        li {
          width: 160px;
          margin: 0 10px 20px;
        }
      }
    }
  }
  .authentication {
    .top {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 400px;
      background: url("../assets/image/home/背景图@2x.jpg") no-repeat center;
      background-size: cover;
      text-align: center;
      color: #ffffff;
      p {
        max-width: 1200px;
        margin: auto;
        font-size: 40px;
        color: #ffffff;
        strong {
          font-weight: 900;
        }
      }
      i {
        position: absolute;
        font-size: 25px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 43px;
      }
    }
    @media screen and (max-width: 992px) {
      .top {
        height: 280px;
        p {
          font-size: 24px;
        }
        i {
          bottom: 23px;
        }
      }
    }
    .bottom {
      display: flex;
      margin: 70px auto 80px;
      justify-content: center;
      flex-wrap: wrap;
      .item {
        display: flex;
        max-width: 320px;
        margin: 0 100px;
        flex-direction: column;
        align-items: center;
        color: #333333;
        img {
          height: 180px;
          width: 180px;
        }
        p {
          font-size: 14px;
          color: #333333;
          text-align: center;
        }
      }
      @media screen and (max-width: 992px) {
        margin: 0px auto 45px;
        .item {
          margin: 0 50px;
        }
      }
      @media screen and (max-width: 768px) {
        margin: 0px auto 45px;
        .item {
          margin: 0px;
        }
      }
    }
  }
  .partner {
    background-color: #f7f7f7;
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        margin: 0 30px 50px;
        img {
          width: 230px;
          height: 130px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      ul {
        margin: 0 -18px;
        li {
          margin: 0 10px 20px;
          img {
            width: 160px;
            height: 90.5px;
          }
        }
      }
    }
  }
  .download {
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        margin: 0 30px;
        img {
          width: 180px;
          height: 50px;
        }
      }
      @media screen and (max-width: 992px) {
        li {
          margin: 0 8px 30px;
          img {
            width: 150px;
            height: 41.6px;
          }
        }
      }
    }
  }
}
</style>
